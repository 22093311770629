import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-download-app-warning-dialog',
  templateUrl: './download-app-warning-dialog.component.html',
  styleUrls: ['./download-app-warning-dialog.component.scss'],
})
export class DownloadAppWarningDialogComponent {

  public constructor(
    public reference: MatDialogRef<DownloadAppWarningDialogComponent>,
  ) {}

  public dismiss(): void {
    this.reference.close();
  }
}
