import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, RoleGuard } from '@rspl-auth';
import { Role } from '@domains';
import { HealthCheckComponent } from '@rspl-ui';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/donations',
    pathMatch: 'full'
  },
  {
    path: 'donations',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.DRIVER]},
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@driver/driver/driver.module')
            .then(module => module.DriverModule)
      },
    ]
  },
  {
    path: 'hc',
    component: HealthCheckComponent,
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
