import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Donation, RESCHEDULE_REASONS, Reason } from '@domains';

@Component({
  selector: 'rspl-request-reschedule-dialog',
  templateUrl: './request-reschedule-dialog.component.html',
  styleUrls: ['./request-reschedule-dialog.component.scss']
})
export class RequestRescheduleDialogComponent {
  reasons: Reason[];
  reason: FormControl<Reason>;
  subReason: FormControl<string>;
  selectedReason: Reason;
  additionalText: FormControl<string> = new FormControl<string>('');

  public constructor(
    public reference: MatDialogRef<RequestRescheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Donation
  ) {
    this.reason = new FormControl<Reason>(null, Validators.required);
    this.subReason = new FormControl<string>(null, Validators.required);
    this.reasons = RESCHEDULE_REASONS;
  }

  public onChoice(choice: boolean): void {
    let reason = '';
    let note = '';
    if (choice) {
      this.reason.updateValueAndValidity();
      this.reason.markAllAsTouched();
      this.subReason.updateValueAndValidity();
      this.subReason.markAllAsTouched();
      this.additionalText.updateValueAndValidity();
      this.additionalText.markAllAsTouched();
      if (this.reason.invalid || this.subReason.invalid || this.additionalText.invalid) {
        return;
      }

      reason = this.reason.value.reason + (this.subReason.value ? (' : ' + this.subReason.value) : '');
      note = this.additionalText.value;
    }

    
    this.reference.close(choice ? {
      reason,
      note,
    } : null);
  }

  setSelectedReason(): void {
    this.selectedReason = this.reason.value;
    if (this.selectedReason.children) {
      this.subReason.setValidators(Validators.required);
      this.additionalText.clearValidators();
      this.subReason.updateValueAndValidity();
      this.additionalText.updateValueAndValidity();
    } else {
      this.subReason.clearValidators();
      this.additionalText.setValidators(Validators.required);
      this.additionalText.updateValueAndValidity();
    }
  }
}
