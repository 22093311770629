import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ENVIRONMENT, Environment, Role } from '@domains';
import { LocalStorageService } from '@rspl-api';
import { Observable, map, take } from 'rxjs';
import { AFTER_LOGIN, AuthenticationService } from '../authentication.service';

@Injectable({ providedIn: 'root' })
export class RoleGuard  {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    @Inject(ENVIRONMENT) private config: Environment,
    private localStorage: LocalStorageService
    ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const allowedRoles = route.data['roles'] as Array<Role>;
    return this.authService.authData$.pipe(
      take(1),
      map((authData) => {
        if (authData?.authorization?.auth) {
          for (const allowedRole of allowedRoles) {
            if (authData?.authorization?.roles.includes(allowedRole)) {
              return true;
            }
          }
          this.snackBar.open('Access Not Allowed', 'x', {
            duration: 5000,
      panelClass: 'error'
          });
          if (!this.config.production) {
            this.localStorage.setItem(AFTER_LOGIN, state.url);
            this.authService.logout();
          } else {
            this.router.navigate(this.config.defaultRoute);
          }
          return false;
        } else {
          this.localStorage.setItem(AFTER_LOGIN, state.url);
          this.authService.logout();
          return false;
        }
      })
    );
  }
}
