import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ENVIRONMENT, Environment } from '@domains';
import { Observable, map, take } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Injectable({ providedIn: 'root' })
export class UnauthGuard  {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    @Inject(ENVIRONMENT) private config: Environment
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.authData$.pipe(
      take(1),
      map((authData) => {
        if (authData?.authorization?.auth) {
          this.router.navigate(this.config.defaultRoute);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
