<h2 mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
  <span>Download New App by October, 30 (10/30)</span>
  <img class="logo" src="assets/images/rspl_logo.png" alt="Resupply">
</h2>
<div mat-dialog-content class="download-dialog">
  <div class="warning-note">
    <p><b>This website will be closing on October 30, 2024.</b> Until then, you can contact Brad Knittle at
      <a href="mailto:brad@resupplyme.com">{{'brad@resupplyme.com'}}</a> for assistance. After downloading the app, please use
      the Help tab in the app for any
      additional support.
    </p>
  </div>
  <h3>Download the app on your respective store below:</h3>
  <div class="store-options" fxLayout="row" fxLayoutGap="10px">
    <a href="https://play.google.com/store/apps/details?id=com.resupply.driverappv2&pcampaignid=web_share">
      <img src="assets/images/playstore.png" alt="Google Play Store">
    </a>
    <a href="https://apps.apple.com/rs/app/resupply-driver-app-v2/id6470944176">
      <img src="assets/images/appstore.png" alt="Apple App Store">
    </a>
  </div>
  <p>ReSupply’s new mobile app for drivers is now available on iOS and Android. Simply search for <b>'ReSupply Driver
      App
      V2'</b> in the App Store or Google Play, download it, and <b>log in with your existing credentials.</b>
  </p>
  <div class="app-facts">
    <p>With the new app, you will now be able to:</p>
    <ul>
      <li fxLayout="row" fxLayoutAlign="start center"><img src="assets/images/ic_check.svg"><span>Accept and start jobs
          from one central location</span></li>
      <li fxLayout="row" fxLayoutAlign="start center"><img src="assets/images/ic_check.svg"><span>Easily select and
          upload multiple photos at once to the app for donations</span></li>
      <li fxLayout="row" fxLayoutAlign="start center"><img src="assets/images/ic_check.svg"><span>Simplify the pickup
          process with a more streamlined and efficient experience</span></li>
      <li fxLayout="row" fxLayoutAlign="start center"><img src="assets/images/ic_check.svg"><span>Use a 'Help' tab to
          connect with Driver Support directly from the app</span></li>
    </ul>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button class="primary-btn filled-btn block-btn" (click)="dismiss()">OK, got it!</button>
  </div>
</div>