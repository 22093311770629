import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { environment } from '@driver/../environments/environment';
import { MaterialImportsModule } from '@driver/import-modules/material-imports.module';
import { RsplImportsModule } from '@driver/import-modules/rspl-imports.module';
import { LaddaModule } from 'angular2-ladda';
import { QRCodeModule } from 'angularx-qrcode';
import {
  MatTableExporterDirective,
  MatTableExporterModule,
} from 'mat-table-exporter';
import {
  NgxCurrencyDirective,
  NgxCurrencyInputMode,
  provideEnvironmentNgxCurrency,
} from 'ngx-currency';
import { MapService, NgxMapboxGLModule } from 'ngx-mapbox-gl';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialImportsModule,
    RsplImportsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableExporterModule,
    RouterModule,
    NgxCurrencyDirective,
    LaddaModule.forRoot({
      style: 'zoom-in',
    }),
    QRCodeModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapBoxAccessToken,
    }),
  ],
  exports: [
    MaterialImportsModule,
    RsplImportsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableExporterDirective,
    NgxCurrencyDirective,
    CommonModule,
    LaddaModule,
    QRCodeModule,
    NgxMapboxGLModule,
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    MapService,
    provideEnvironmentNgxCurrency({
      align: 'left',
      allowNegative: false,
      allowZero: true,
      decimal: '.',
      precision: 2,
      prefix: '$ ',
      suffix: '',
      thousands: ',',
      nullable: false,
      min: 0,
      inputMode: NgxCurrencyInputMode.Natural,
    }),
  ],
})
export class CoreModule {}
