import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { BaseDTO } from '@domains';
import { BaseApiService } from '@rspl-api';
import { Observable, filter, takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';
import { Destructible } from '../destructible';

type LookupGroup = {
  item: any;
  children: LookupGroup[];
};

@Component({
  template: '',
})
export abstract class LookupComponent<T extends BaseDTO>
  extends Destructible
  implements OnInit
{
  @HostBinding('style.display') display = 'flex';
  @ViewChild(MatSelect) matSelect!: MatSelect;
  @Output() onItemSelected = new EventEmitter<T | undefined | null>(undefined);
  @Output() onItemsSelected = new EventEmitter<T[]>();

  @Input() groupBy: [] | [string] | [string, string] = [];
  grouped: LookupGroup[] = [];

  @Input() formatLabel?: (item: T) => string;

  @Input() showSearchIcon = true;
  @Input() requiresFilters = false;
  @Input() showError = false;
  @Input() showSelectAll = false;
  @Input() itemsPerPage?: number;
  @Input() refreshOnFilterChange = false;
  @Input() shouldSort = true;
  #filters?: { [key: string]: any };
  @Input() set filters(filters: { [key: string]: any } | undefined) {
    if (
      (!filters && this.requiresFilters) ||
      JSON.stringify(filters) !== JSON.stringify(this.filters)
    ) {
      if (this.filters) this.clear();
      this.shouldOpen = false;
    }
    this.#filters = filters;
    if (this.refreshOnFilterChange) {
      this.filter(false);
    }
  }
  get filters(): { [key: string]: any } | undefined {
    return this.#filters;
  }

  #currentId?: string;
  @Input()
  set currentId(currentId: string | undefined) {
    this.#currentId = currentId?.toString();
    this.setItem();
  }
  get currentId(): string | undefined {
    return this.#currentId;
  }

  #currentIds: string[] = [];
  @Input()
  set currentIds(currentIds: string[]) {
    if (currentIds) {
      this.#currentIds = currentIds?.map((id) => id.toString()) || [];
      this.setItem();
    }
  }
  get currentIds(): string[] {
    return this.#currentIds;
  }

  #required = false;
  @Input() set required(required: boolean) {
    this.#required = required;
    this.formControl.setValidators(this.required ? [Validators.required] : []);
  }
  get required(): boolean {
    return this.#required;
  }

  #disabled = false;
  @Input() set disabled(disabled: boolean) {
    this.#disabled = disabled;
    if (this.disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
  get disabled(): boolean {
    return this.#disabled;
  }

  @Input() multiple = false;
  @Input() label: string = 'Select';
  @Input() expand: string[] = [];
  @Input() onAddNew?: (name: string) => Observable<T>;
  @Input() addNewLabel: string;

  protected totalResults?: number;
  protected results?: T[] = [];
  private timeout: any;
  private page = 1;
  private itemFetched = false;
  private shouldOpen = false;
  protected firstLoadFinished = false;
  protected isLoading: boolean = false;
  protected isSelectedIncluded: boolean = false;
  protected filterControl = new FormControl<string>('');

  formControl = new FormControl<T | undefined>(undefined);
  formControlMultiple = new FormControl<T[]>([]);
  selectedItem?: T | null = undefined;
  selectedItems?: T[] | null = undefined;
  focusedIndex: number = 0;

  @Input() disableOption?: (item: T) => boolean;
  protected disabledLabel: string = '';
  isFilterReset = false;

  constructor(
    protected apiService: BaseApiService<T>,
    protected dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.apiService.lookupItems$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.firstLoadFinished = true;
        this.totalResults = data.totalResults;
        this.focusedIndex = 0;
        this.results = this.shouldSort
          ? data.results?.sort((a, b) => this.sort(a, b))
          : data.results || [];
        if (this.groupBy.length > 0) {
          this.grouped = this.groupResults(
            this.results.map((x) => ({ item: x, children: [] })),
            0
          );
        }
        this.isLoading = !!data.loading;
        this.isSelectedIncluded =
          !this.isLoading &&
          !!data &&
          !!this.currentId &&
          !!this.selectedItem &&
          !!this.results?.find((x) => x.id === this.currentId);
        if (!this.isLoading) {
          this.setItem();
        }
        if (!this.isLoading && this.shouldOpen) {
          this.shouldOpen = false;
          setTimeout(() => {
            this.matSelect.open();
          });
        }
      });
    this.filterControl.valueChanges
      .pipe(
        filter((value) => typeof value === 'string'),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => this.filter(true));
  }

  protected setItem(): void {
    if (!this.firstLoadFinished) return;
    if (!this.currentId && this.currentIds.length === 0) {
      this.formControl.setValue(undefined);
      this.selectedItem = this.formControl.value;
      this.formControlMultiple.setValue([]);
      this.selectedItem = this.formControl.value;
    } else {
      const ids = this.multiple ? this.currentIds : [this.currentId];
      const items = this.results?.filter((x) => ids.includes(x.id));
      if (items?.length === ids.length) {
        if (this.multiple) {
          this.formControlMultiple.setValue(items);
          this.selectedItems = this.formControlMultiple.value;
        } else {
          this.formControl.setValue(items[0]);
          this.selectedItem = this.formControl.value;
        }
      } else if (!this.itemFetched) {
        this.getByIds(ids);
      }
      this.itemFetched = true;
    }
  }

  private getByIds(ids: (string | undefined)[]) {
    this.apiService
      .filter({
        'id[]': ids,
        ...this.filters,
      })
      .pipe(take(1))
      .subscribe((response) => {
        this.results = response.results;
        if (this.multiple) {
          this.formControlMultiple.setValue(response.results);
          this.selectedItems = this.formControlMultiple.value;
        } else {
          this.formControl.setValue(response.results[0]);
          this.selectedItem = this.formControl.value;
        }
      });
  }

  protected filter(useTimeout = true) {
    if (this.isFilterReset) {
      this.isFilterReset = false;
      return;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (useTimeout) {
      this.timeout = setTimeout(() => this.delayedFilter(), 500);
    } else {
      this.delayedFilter();
    }
  }

  protected delayedFilter() {
    this.page = 1;
    if (!this.requiresFilters || !!this.filters) {
      this.apiService.lookup(
        this.filterControl.value,
        1,
        Object.keys(this.expand).length > 0 ? this.expand : this.groupBy,
        this.filters,
        this.itemsPerPage
      );
    }
  }

  protected sort(a: T, b: T): number {
    return (a.name || '').localeCompare(b.name || '');
  }

  protected itemSelected(): void {
    if (this.multiple) {
      this.selectedItems = this.formControlMultiple.value;
      this.currentIds = this.selectedItems?.map((x) => x.id || '') || [];
      this.onItemsSelected.emit(this.formControlMultiple.value || []);
    } else {
      this.selectedItem = this.formControl.value;
      this.currentId = this.selectedItem?.id;
      this.onItemSelected.emit(this.formControl.value);
    }
  }

  clear(event?: MouseEvent): void {
    this.formControl.setValue(null);
    this.formControlMultiple.setValue([]);
    this.itemSelected();
    event?.stopPropagation();
  }

  clearSearch(event?: MouseEvent): void {
    this.filterControl.setValue('');
    event?.stopPropagation();
  }

  focusFilter(filterInput: HTMLInputElement, $event: boolean): void {
    if ($event) {
      filterInput.focus();
    } else {
      if (
        !!this.selectedItems?.find(
          (s) => !this.results?.find((x) => x.id === s.id)
        )
      ) {
        this.isFilterReset = true;
        this.filterControl.patchValue('');
        const ids = this.multiple ? this.currentIds : [this.currentId];
        this.getByIds(ids);
      } else if (
        this.filterControl.value?.length > 0 &&
        this.results.length === 0
      ) {
        this.filterControl.patchValue('');
      }
    }
  }

  open() {
    this.shouldOpen = true;
    this.matSelect.open();
    this.filter(false);
  }

  loadMore(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.requiresFilters || !!this.filters) {
      this.page += 1;
      this.apiService.lookup(
        this.filterControl.value,
        this.page,
        Object.keys(this.expand).length > 0 ? this.expand : this.groupBy,
        this.filters
      );
    }
  }

  groupResults(items: LookupGroup[], groupByIndex: number): LookupGroup[] {
    if (!this.groupBy[groupByIndex]) return items;
    const grouped: LookupGroup[] = [];
    items.forEach((x: any) => {
      const parent: any = x.item[this.groupBy[groupByIndex]];
      const parentId = parent?.id || 'UNASSIGNED';
      let groupParentIndex = grouped?.findIndex((x) => x.item?.id === parentId);
      if (groupParentIndex < 0) {
        grouped.push({
          item: parent,
          children: [],
        });
        groupParentIndex = grouped.length - 1;
      }
      grouped[groupParentIndex].children.push(x);
    });
    const sorted = this.shouldSort
      ? grouped.sort((a, b) =>
          (a.item?.name || 'zzzzzz')?.localeCompare(b?.item?.name || 'zzzzzz')
        )
      : grouped;
    return sorted;
  }

  toggleAll(): void {
    if (this.formControlMultiple.value?.length !== this.totalResults) {
      this.formControlMultiple.patchValue(this.results || []);
    } else {
      this.formControlMultiple.patchValue([]);
    }
    this.itemSelected();
  }

  addNew() {
    this.onAddNew(this.filterControl.value)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.filterControl.setValue('');
          this.delayedFilter();
          this.matSelect.close();
        }
      });
  }

  @HostListener('document:keydown.ArrowUp', ['$event'])
  handleArrowUp(event: KeyboardEvent) {
    if (!this.matSelect.panelOpen || this.multiple) return;
    event.preventDefault();
    event.stopPropagation();
    const container: any = document.querySelector(`.lookup-scroll-wrapper`);
    if (!container) return;
    this.focusedIndex = Math.max(this.focusedIndex - 1, 0);
    const item: any = document.querySelector(
      `.lookup-scroll-wrapper [data-index='${this.focusedIndex}']`
    );
    if (!item) {
      return;
    }
    (document.querySelector(`.lookup-scroll-wrapper button`) as any)?.blur();
    const containerTop = container.scrollTop;
    const itemTop = item.offsetTop;
    if (itemTop <= containerTop + 42) {
      container.scroll({
        top: item.offsetTop - container.offsetTop,
        behavior: 'smooth',
      });
    }
  }

  @HostListener('document:keydown.ArrowDown', ['$event'])
  handleArrowDown(event: KeyboardEvent) {
    if (this.multiple) return;
    if (
      !this.matSelect.panelOpen &&
      event.target === this.matSelect._elementRef.nativeElement
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.open();
      return;
    }
    if (!this.matSelect.panelOpen) return;
    const container: any = document.querySelector(`.lookup-scroll-wrapper`);
    if (!container) return;
    const containerTop = container.scrollTop;
    const containerBottom = containerTop + 256;
    event.preventDefault();
    event.stopPropagation();
    this.focusedIndex++;
    let item;
    if (this.focusedIndex > this.totalResults - 1) {
      if (
        !this.isLoading &&
        (this.totalResults || 0) > (this.results?.length || 0)
      ) {
        this.focusedIndex = this.totalResults;
        item = document.querySelector(
          `.lookup-scroll-wrapper .load-more-button`
        );
      } else if (
        this.onAddNew &&
        !this.isLoading &&
        (this.totalResults || 0) === (this.results?.length || 0)
      ) {
        item = document.querySelector(`.lookup-scroll-wrapper .add-new-button`);
      }
      if (item) {
        this.focusedIndex = this.totalResults;
        item.focus();
      } else {
        this.focusedIndex = this.totalResults - 1;
        return;
      }
    } else {
      this.focusedIndex = Math.min(this.focusedIndex, this.totalResults - 1);
      item = document.querySelector(
        `.lookup-scroll-wrapper [data-index='${this.focusedIndex}']`
      );
    }
    // next item
    const itemTop = item.offsetTop;
    const itemBottom = itemTop + item.offsetHeight;
    if (itemBottom >= containerBottom) {
      container.scroll({
        top:
          this.focusedIndex === this.totalResults
            ? container.offsetHeight
            : item.offsetTop -
              container.offsetTop -
              container.offsetHeight +
              item.offsetHeight,
        behavior: 'smooth',
      });
    }
  }

  @HostListener('document:keyup.Enter', ['$event'])
  handleEnter(event: KeyboardEvent) {
    if (this.multiple) return;
    if (
      !this.matSelect.panelOpen &&
      event.target === this.matSelect._elementRef.nativeElement
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.open();
      return;
    }
    if (!this.matSelect.panelOpen) return;
    event.preventDefault();
    event.stopPropagation();
    if (this.focusedIndex === this.totalResults) return;
    this.formControl.setValue(this.results[this.focusedIndex]);
    this.itemSelected();
    this.matSelect.close();
  }

  onOpenTry(event: Event) {
    if (this.multiple) return;
    event.preventDefault();
    event.stopPropagation();
  }
}
