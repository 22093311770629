import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { RsplUIModule } from '@rspl-ui';
import { LaddaModule } from 'angular2-ladda';
import { LoginComponent } from './components/login/login.component';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { RsplAuthRoutingModule } from './rspl-auth-routing.module';

@NgModule({ declarations: [LoginComponent],
    exports: [LoginComponent], imports: [CommonModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        RsplAuthRoutingModule,
        RsplUIModule,
        LaddaModule.forRoot({
            style: 'zoom-in',
        })], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class RsplAuthModule {
  public static forRoot(): ModuleWithProviders<RsplAuthModule> {
    return {
      ngModule: RsplAuthModule,
      providers: [
        RsplAuthModule,
        JwtInterceptor,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true,
        },
      ],
    };
  }
}
